var function_list = [{
    'label': 'ChatGPT Chat Completion API (chatgpt_v1_chat_completion)',
    'function': 'chatgpt_v1_chat_completion()',
    'info': 'ChatGPT Chat Completion API mesajına kolondaki hücre içindeki ifadede verileri bulması istenir.',
    'example': 'chatgpt_v1_chat_completion(\'{"model": "gpt-3.5-turbo", "messages": [{"role": "user", "content": "olumsuz ifade varsa evet olarak belirt \\n"}], "temperature": 0.7, "apikey": "xxxxxxx" }\', [col1])'
  },
  {
    'label': 'NLP Doğal Dil İşleme (nlp)',
    'function': 'nlp()',
    'info': 'Belirtilen ifade ile kolondaki yazıda doğal dil işleme analizi yaparak sonucun hücreye yazılmasını sağlar.',
    'example': 'nlp(\'ifade\', [col1])'
  },
  {
    'label': 'Tarih yapısından düzenli bir format al (time_format)',
    'function': 'time_format()',
    'info': 'Belirtilen bir kolondaki hücrede görüntülenen tarih verisinden, tarihsel formatı hedeflenen şekilde alınmasını sağlar. Kullanılabilecek çeşitli tarihsel formatlar vardır. Bu formatlardan aralarına istenilen ek karakter ya da boşluklar konularak alınabilir. %a: hafta gününün kısaltılmış adı | %A: hafta gününün tam adı | %b: ayın kısaltılmış adı | %B: ayın tam adı | %c: tam tarih, saat ve zaman bilgisi | %d: sayı değerli bir karakter dizisi olarak gün | %f: sayı değerli bir karakter dizisi olarak mikrosaniye | %H: sayı değerli bir karakter dizisi olarak saat | %j: belli bir tarihin, yılın kaçıncı gününe denk geldiğini gösteren 1-366 arası bir sayı | %m: sayı değerli bir karakter dizisi olarak ay | %M: sayı değerli bir karakter dizisi olarak dakika | %S: sayı değerli bir karakter dizisi olarak saniye | %U: belli bir tarihin yılın kaçıncı haftasına geldiğini gösteren 0-53 arası bir sayı | %X: tam saat bilgisi | %x: tam tarih bilgisi | %Y: yılın dört haneli tam hali | %y: yılın son iki rakamı',
    'example': 'time_format(\'%d.%m.%Y %H:%M\', [col3])'
  },
  {
    'label': 'başlangıçtan x. sıradaki değeri al, tarih listesi ile gruplayarak (get_value_of_this_order_group_by_date_from_start)',
    'function': 'get_value_of_this_order_group_by_date_from_start()',
    'info': 'Çoklu hücre verilerindeki değerlerin tarih listesi ile gruplayarak sıradaki x. değeri verir.',
    'example': 'get_value_of_this_order_group_by_date_from_start([col1], 2)'
  },
  {
    'label': 'başlangıçtan x. sıradaki değeri al, tarih listesinden gruplandırmadan (get_value_of_this_order_not_group_by_date_from_start)',
    'function': 'get_value_of_this_order_not_group_by_date_from_start()',
    'info': 'Çoklu hücre verilerindeki değerlerin tarih baz alınmadan sıradaki x. değeri verir.',
    'example': 'get_value_of_this_order_not_group_by_date_from_start([col1], 2)'
  },
  {
    'label': 'başlangıçtan x. tarih değerini al (get_date_of_this_order_from_start)',
    'function': 'get_date_of_this_order_from_start()',
    'info': 'Çoklu tarih bilgisini içeren kolon hücrelerindeki en eski tarihten x. sıradaki tarihi alır.',
    'example': 'get_date_of_this_order_from_start([col1], 1)'
  },
  {
    'label': 'belirtilen tarihten x gün önce ve sonra aralığında olan değerleri al (get_val_between_date)',
    'function': 'get_val_between_date()',
    'info': 'Birden fazla değer barındıran ve tarihsel bir veri olan wdmr kolonlarında belirli bir tarih aralığındaki değerler başka bir kolona belirlenerek aktarılması gerektiğinde kullanılabilir.',
    'example': 'get_val_between_date([col35], get_min_date(col20), -10, 10)'
  },
  {
    'label': 'bugün tarihini al (today)',
    'function': 'today()',
    'info': 'Bugün tarihsel değeri fonksiyonda gerektiğinde kullanılmaktadır.',
    'example': 'today()'
  },
  {
    'label': 'büyük olan ilk en küçük değeri ata (select_first_upper)',
    'function': 'select_first_upper()',
    'info': 'Bir hücredeki sayısal bir değerin, belirtilen değerler arasında kendisinden büyük olan sayılardan en küçük olan değerini verir.',
    'example': 'select_first_upper(col1, \'60\', \'40\', \'50\', \'80\')'
  },
  {
    'label': 'düzenli ifade ile tekil olarak verileri al (regx_unique)',
    'function': 'regx_unique()',
    'info': 'Belirtilen kolon hücresindeki text veri üzerine çalıştırılan regular expression fonksiyonu ile çıktılar liste halinde tekil olarak alınır.',
    'example': 'regx_unique(\'^e.*v\', [col1])'
  },
  {
    'label': 'düzenli ifade ile verileri al (regx)',
    'function': 'regx()',
    'info': 'Belirtilen kolon hücresindeki text veri üzerine çalıştırılan regular expression fonksiyonu ile çıktılar alınır.',
    'example': 'regx(\'^e.*v\', [col1])'
  },
  {
    'label': 'düzenli ifade ile çoklayarak verileri al (regx_multiplex)',
    'function': 'regx_multiplex()',
    'info': 'Belirtilen kolon hücresindeki text veri üzerine çalıştırılan regular expression fonksiyonu ile çıktılar liste halinde çoklayarak alınır.',
    'example': 'regx_multiplex(\'^e.*v\', [col1])'
  },
  {
    'label': 'en eski tarihi al (get_min_date)',
    'function': 'get_min_date()',
    'info': 'Bir kolona ait hücredeki değer çoklu ise, hücredeki değerlerin tarihlerinden en eski tarihin tarihsel değeri fonksiyonlarda gerekli ise kullanılmaktadır.',
    'example': 'get_min_date(col1)'
  },
  {
    'label': 'en yeni tarihi al (get_max_date)',
    'function': 'get_max_date()',
    'info': 'Bir kolona ait hücredeki değer çoklu ise, hücredeki değerlerin tarihlerinden en yeni tarihin tarihsel değeri fonksiyonlarda gerekli ise kullanılmaktadır.',
    'example': 'get_max_date(col1)'
  },
  {
    'label': 'ifadeye ait başlangıçtan x. sıradaki değerin tarihini al (get_date_of_value_from_start)',
    'function': 'get_date_of_value_from_start()',
    'info': 'Magic Cell yapısına sahip bir hücredeki, belirtilen ifadeye ait değerler arasında, tarihsel sıralama işlemi yapıldığında başlangıçtan belirli bir sırada değerin tarihini verir. Hücrede söz konusu değere ait ait belirtilen sırada bir değer bulunamazsa fonksiyon boş olarak sonuçlanır.',
    'example': 'get_date_of_value_from_start([col1], 2, "Covid PCR")'
  },
  {
    'label': 'seçilen 1 ya da birden fazla kolon içerisindeki verileri toplar (sum_cell_values)',
    'function': 'sum_cell_values()',
    'info': 'Bir kolon belirtildiğinde, birden fazla veri olabilen hücredeki verileri toplar. Birden fazla kolon belirtildiğinde, belirtilen tüm kolonlardaki verileri toplar.',
    'example': 'sum_cell_values([col1], [col2])'
  },
  {
    'label': 'ifadeye ait sondan x. sıradaki değerin tarihini al (get_date_of_value_from_end)',
    'function': 'get_date_of_value_from_end()',
    'info': 'Magic Cell yapısına sahip bir hücredeki, belirtilen ifadeye ait değerler arasında, tarihsel sıralama işlemi yapıldığında sondan belirli bir sırada değerin tarihini verir. Hücrede söz konusu değere ait ait belirtilen sırada bir değer bulunamazsa fonksiyon boş olarak sonuçlanır.',
    'example': 'get_date_of_value_from_end([col1], 2, "Covid PCR")'
  },
  {
    'label': 'maksimum değeri al (max)',
    'function': 'max()',
    'info': 'Bir kolona ait hücredeki değer çoklu ise bu değerlerin maksimum değeri yeni bir kolon oluştururken kullanılabilmektedir.',
    'example': 'max(col1)'
  },
  {
    'label': 'medyan değeri al (median)',
    'function': 'median()',
    'info': 'Bir kolona ait hücredeki değer çoklu ise bu değerlerin ortanca değeri yeni bir kolon oluştururken kullanılabilmektedir.',
    'example': 'median(col1)'
  },
  {
    'label': 'minimum değeri al (min)',
    'function': 'min()',
    'info': 'Bir kolona ait hücredeki değer çoklu ise bu değerlerin minimum değeri yeni bir kolon oluştururken kullanılabilmektedir.',
    'example': 'min(col1)'
  },
  {
    'label': 'ortalama değeri al (mean)',
    'function': 'mean()',
    'info': 'Bir kolona ait hücredeki değer çoklu ise bu değerlerin ortalama değeri yeni bir kolon oluştururken kullanılabilmektedir.',
    'example': 'mean(col1)'
  },
  {
    'label': 'sondan x. sıradaki değeri al, tarih listesi ile gruplayarak (get_value_of_this_order_group_by_date_from_end)',
    'function': 'get_value_of_this_order_group_by_date_from_end()',
    'info': 'Çoklu hücre verilerindeki değerlerin tarih listesi ile gruplayarak sıradaki x. değeri verir.',
    'example': 'get_value_of_this_order_group_by_date_from_end([col1], 2)'
  },
  {
    'label': 'sondan x. sıradaki değeri al, tarih listesinden gruplandırmadan (get_value_of_this_order_not_group_by_date_from_end)',
    'function': 'get_value_of_this_order_not_group_by_date_from_end()',
    'info': 'Çoklu hücre verilerindeki değerlerin tarih baz alınmadan sıradaki x. değeri verir.',
    'example': 'get_value_of_this_order_not_group_by_date_from_end([col1], 2)'
  },
  {
    'label': 'sondan x. tarih değerini al (get_date_of_this_order_from_end)',
    'function': 'get_date_of_this_order_from_end()',
    'info': 'Çoklu tarih bilgisini içeren kolon hücrelerindeki en yeni tarihten x. sıradaki tarihi alır.',
    'example': 'get_date_of_this_order_from_end([col1], 1)'
  },
  {
    'label': 'tam sayıya yuvarla (round)',
    'function': 'round()',
    'info': 'Ondalıklı bir sayısal değeri tam sayı kısmına yuvarlamak için kullanılmaktadır.',
    'example': 'round(col1)'
  },
  {
    'label': 'tarih farkını ay olarak al (time_dif_months)',
    'function': 'time_dif_months()',
    'info': 'Birinci tarihten, ikinci tarihi çıkartarak farkı ay cinsinden gerekiyorsa kullanılmaktadır.',
    'example': 'time_dif_months(tarih1, tarih2)'
  },
  {
    'label': 'tarih farkını dakika olarak al (time_dif_minutes)',
    'function': 'time_dif_minutes()',
    'info': 'Birinci tarihten, ikinci tarihi çıkartarak farkı dakika cinsinden gerekiyorsa kullanılmaktadır.',
    'example': 'time_dif_minutes(tarih1, tarih2)'
  },
  {
    'label': 'tarih farkını gün olarak al (time_dif_days)',
    'function': 'time_dif_days()',
    'info': 'Birinci tarihten, ikinci tarihi çıkartarak farkı saat cinsinden gerekiyorsa kullanılmaktadır.',
    'example': 'time_dif_days(tarih1, tarih2)'
  },
  {
    'label': 'tarih farkını saat olarak al (time_dif_hours)',
    'function': 'time_dif_hours()',
    'info': 'Birinci tarihten, ikinci tarihi çıkartarak farkı saat cinsinden gerekiyorsa kullanılmaktadır.',
    'example': 'time_dif_hours(tarih1, tarih2)'
  },
  {
    'label': 'tarih farkını saniye olarak al (time_dif_seconds)',
    'function': 'time_dif_seconds()',
    'info': 'Birinci tarihten, ikinci tarihi çıkartarak farkı saniye cinsinden gerekiyorsa kullanılmaktadır.',
    'example': 'time_dif_seconds(tarih1, tarih2)'
  },
  {
    'label': 'tarih farkını yıl olarak al (time_dif_years)',
    'function': 'time_dif_years()',
    'info': 'Birinci tarihten, ikinci tarihi çıkartarak farkı yıl cinsinden gerekiyorsa kullanılmaktadır.',
    'example': 'time_dif_years(tarih1, tarih2)'
  },
  {
    'label': 'tarihi en eski olan veriyi al (date_min)',
    'function': 'date_min()',
    'info': 'Bir kolona ait hücredeki değer çoklu ise, değerlerden tarihi en eski olanının değeri, yeni bir kolon oluştururken kullanılabilmektedir.',
    'example': 'date_min(col1)'
  },
  {
    'label': 'tarihi en yeni olan veriyi al (date_max)',
    'function': 'date_max()',
    'info': 'Bir kolona ait hücredeki değer çoklu ise, değerlerden tarihi en yeni olanının değeri, yeni bir kolon oluştururken kullanılabilmektedir.',
    'example': 'date_max(col1)'
  },
  {
    'label': 'tarihin ay sayısal değerini al (month_of_date_as_numeric)',
    'function': 'month_of_date_as_numeric()',
    'info': 'Bir tarihin ay değerini sayısal olarak verir.',
    'example': 'month_of_date_as_numeric(col1)'
  },
  {
    'label': 'tarihin ay yazı değerini al (month_of_date_as_text)',
    'function': 'month_of_date_as_text()',
    'info': 'Bir tarihin ay değerini yazı olarak verir.',
    'example': 'month_of_date_as_text(col1)'
  },
  {
    'label': 'tarihin gün değerini al (day_of_date)',
    'function': 'day_of_date()',
    'info': 'Bir tarihin gün değerini verir.',
    'example': 'day_of_date(col1)'
  },
  {
    'label': 'tarihin gün yazı değerini al (day_of_date_as_text)',
    'function': 'day_of_date_as_text()',
    'info': 'Bir tarihin gün değerini yazı olarak verir.',
    'example': 'day_of_date_as_text(col1)'
  },
  {
    'label': 'tarihin saat değerini al (hour_of_date)',
    'function': 'hour_of_date()',
    'info': 'Bir tarihin saat değerini verir.',
    'example': 'hour_of_date(col1)'
  },
  {
    'label': 'tarihin yıl değerini al (year_of_date)',
    'function': 'year_of_date()',
    'info': 'Bir tarihin yıl değerini verir.',
    'example': 'year_of_date(col1)'
  },
  {
    'label': 'tarihin yıl-ay-gün değerini al (year_month_day)',
    'function': 'year_month_day()',
    'info': 'Bir tarihin yıl-ay-gün değerini verir.',
    'example': 'year_month_day(col1)'
  },
  {
    'label': 'x değerine tam bölünen daha büyük değere yuvarla (upper_round)',
    'function': 'upper_round()',
    'info': 'Tam sayı (integer) yapısına sahip bir hücredeki değerin, değerden büyük eşit olan tam sayılar arasından, belirtilen bir sayının (upper_round(col1, 10) örneğinde bu değer 10 olarak girilmiş) tam katına eşit en küçük sayısal ifadeyi verir.',
    'example': 'upper_round(col1, 10)'
  },
  {
    'label': 'x değerine tam bölünen daha küçük ilk değere yuvarla (lower_round)',
    'function': 'lower_round()',
    'info': 'Tam sayı (integer) yapısına sahip bir hücredeki değerin, değerden küçük eşit olan tam sayılar arasından, belirtilen bir sayının (lower_round(col1, 10) örneğinde bu değer 10 olarak girilmiş) tam katına eşit en büyük sayısal ifadeyi verir.',
    'example': 'lower_round(col1, 10)'
  },
  {
    'label': 'x değerine tam bölünen daha küçük veya büyük en yakın ilk değere yuvarla (closer_round)',
    'function': 'closer_round()',
    'info': 'Tam sayı (integer) yapısına sahip bir hücredeki değerin, değere eşit, değerden büyük ya da küçük olan sayılar arasından, belirtilen bir sayının tam katına eşit olan ve yeni değer ile mevcut değer arasındaki farkın en az olduğu sayısal ifadeyi verir.',
    'example': 'closer_round(col1, 10)'
  },
  {
    'label': 'çoklu veriyi tekil olarak al (unique_data_list)',
    'function': 'unique_data_list()',
    'info': 'Seçilen çoklu aynı veriyi içeren hücredeki verileri tekil olarak alır.',
    'example': 'unique_data_list(col1)'
  },
  {
    'label': 'şimdiye ait tarihi al (now)',
    'function': 'now()',
    'info': 'Şimdiye ait tarihsel değeri fonksiyonda gerektiğinde kullanılmaktadır.',
    'example': 'now()'
  },
  {
    'label': 'Birinci kolon verisini, ikinci kolon verisi içeriyor mu (include)',
    'function': 'include()',
    'info': 'Şimdiye ait tarihsel değeri fonksiyonda gerektiğinde kullanılmaktadır.',
    'example': 'include([col1], [col2], "evet", "hayır")'
  },
  {
    'label': 'Hücre içindeki veri adedi (count_of())',
    'function': 'count_of()',
    'info': 'Hücre içindeki veri adedi.',
    'example': 'count_of(col1)'
  },
  {
    'label': 'Hücre içindeki veri adedi (cell_value())',
    'function': 'cell_value()',
    'info': 'Hücre içindeki veri adedi.',
    'example': 'cell_value(col1)'
  }
];

export { function_list as options };
